body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* styles.css */
.dropdown-enter,
.dropdown-leave-active {
  max-height: 0;
  opacity: 0;
  transform: scaleY(0.9);
  pointer-events: none;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.dropdown-enter-active,
.dropdown-leave {
  max-height: 1000px; /* Adjust based on content */
  opacity: 1;
  transform: scaleY(1);
  pointer-events: auto;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.dropdown-enter-active {
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.contact-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1.5rem;
}

@media (max-width: 768px) {
  .contact-section {
    flex-direction: column;
  }
}
